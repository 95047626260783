<template>
    <div>
        <bread-crumb :navigationArray="navigationLink"/>
        <div class="white-container">
            <h5>Модерация обращения</h5>
            <div class="contain">
                <h6>Ваше обращение</h6>
                <div class="contain__block">
                    <p>Уникальный ID обращения </p> <span>№ {{appeal.number}} </span>
                </div>
                <div class="contain__block">
                    <p>Дата поступления</p> <span>{{appeal.date}}</span>
                </div>
                <div class="contain__block">
                    <p>Заголовок</p> <span>{{appeal.title}}</span>
                </div>
                <div class="contain__block">
                    <p>Описание обращения</p> <span>{{appeal.description}}</span>
                </div>
                <div class="contain__block">
                    <p>Категория</p> <span>{{appeal.category}}</span>
                </div>
                <div class="contain__block">
                    <p>Фотографии</p>
                    <div class="contain__photo">
                        <iframe v-for="(item, i) of appeal.photo" :key="i" :style="`background: url('${'/assets/temp/' + item}') center / cover`"></iframe>
                    </div>
                </div>
                <div class="contain__block">
                    <p>Местоположение</p> <span>{{appeal.address}}</span>
                </div>
            </div>
            <p class="confirmation__title">Подтверждение публикации</p>
            <div class="confirmation__block">
                <p>Модерация пройдена</p>
                <div class="checkbox_block">
                    <input class="custom-checkbox" type="checkbox" id="assent" :value="true" v-model="checkboxValue" :class="{'box__active': checkboxValue===true}">
                    <label for="assent"></label>
                    <p>Да</p>
                </div>
                <div class="checkbox_block">
                    <input class="custom-checkbox" type="checkbox" id="assent" :value="false" v-model="checkboxValue" :class="{'box__active': checkboxValue===false}">
                    <label for="assent"></label>
                    <p>Нет</p>
                </div>
            </div>
            <button>Опубликовать</button>
        </div>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'

export default {
  name: 'ModerationAppeal',
  components: {
    'bread-crumb': BreadCrumb
  },
  data () {
    return {
      checkboxValue: '',
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Модерация обращения'
        }
      ],
      appeal: {
        number: 145124,
        date: '27.04.2020 в 16:56',
        title: 'Нарушено окрасочное покрытие стен, потолков подъездов',
        description: '1 подъезд 1 этаж требуется ремонтные работы по окраске всех стен первого этажа, облупилась краска в следствии некачественного ремонта',
        category: 'Экология',
        address: 'Москва, Сергиево-Посадский р-н, Сергиев Посад',
        photo: ['moderator_1.png', 'moderator_2.png', 'moderator_3.png', 'moderator_4.png', 'moderator_5.png']
      }
    }
  },
  mounted () {
    // this.$store.dispatch('getAppeal')
  },
  computed: {
    // getAppeal () {
    //   return this.$store.getters.getAppeal.appeal
    // }
  }
}
</script>
<style lang="scss" scoped>
.white-container{
    width: auto;
    margin: 0 5rem;
    padding: 2.5rem 2.94rem;
}
h5{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
}
h6{
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #1F3245;
    margin-bottom: 1.75rem;
}

.contain{
    width: 100%;
    margin-top: 1.81rem;
    padding: 1.56rem 1.38rem 2.31rem 1.88rem;
    background: #FFFFFF;
    border: .063rem solid #EBF3FF;
    box-sizing: border-box;
    box-shadow: 0px 0.94rem 1.56rem rgba(189, 205, 221, 0.35);
    border-radius: .5rem;
    &__block{
        display: flex;
        margin-bottom: 1.56rem;
        & p{
            width: 22.88rem;
            font-size: .75rem;
            line-height: 1.125rem;
            color: #5E686C;
            margin-right: 4rem;
        }
        & span, div{
            width: calc(100% - 26.88rem);
            font-size: .875rem;
            line-height: 1.38rem;
            color: #1F3245;
        }
    }
    &__block:last-child{
        margin-bottom: 0;
    }
    &__photo{
        display: flex;
        iframe{
            width: 8.75rem;
            height: 5.63rem;
            margin-right: 1.25rem;
        }
    }
}
.confirmation__title{
    font-weight: bold;
    font-size: 1rem;
    line-height: 2rem;
    color: #1F3245;
    margin: 3.13rem 0 1.88rem 0;
}
.confirmation__block{
    display: flex;
    align-items: center;
    padding-bottom: 2.13rem;
    margin-bottom: 2.3125rem;
    border-bottom: .063rem solid #D5D6D9;
    font-size: .75rem;
    p{
        line-height: 1.13rem;
        color: #5E686C;
    }
    span{
        line-height: 1.38rem;
        color: rgba(23, 23, 23, 0.8);
    }
}
button{
    width: 12.19rem;
    position: relative;
    left: calc(100% - 12.19rem);
    padding: .625rem 0;
    font-weight: bold;
    font-size: .875rem;
    line-height: 1.25rem;
    text-align: center;
    color: #FFFFFF;
    background: #246CB7;
    border: 0;
    border-radius: .19rem;
    opacity: 0.5;
    cursor: pointer;
}

.checkbox_block{
    display: flex;
    margin-left: 3.13rem;
    &:last-child{
        margin-left: 2.31rem;
    }
    p{
        font-size: .75rem;
        line-height: 1.125rem;
        color: #1F3245;
        margin-left: .69rem;
    }
    label{
        margin-bottom: 0;
    }
}

.custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: auto !important;
  }
  .custom-checkbox + label::before {
    content: '';
    display: block;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: .19rem;
    background-color: #DDEDFE;
    cursor: pointer;
  }
  .box__active + label::before{
    background: #DDEDFE url("../../assets/icons/check.svg") center center no-repeat;
    background-size: 80%;
}

@media screen and (max-width: 768px){
  .white-container{
    width: auto;
    margin: 0 .75rem;
    padding: 1.86rem 1.25rem;
  }
  .contain .containet_block {
    width: 45%;
  }
}
@media screen and (max-width: 420px){
    .white-container{
        width: 100%;
        margin: 0;
        padding: 1.88rem 1rem;
    }
    .contain .containet_block {
      width: 100%;
      border-radius: .19rem;
      margin-right: 1.25rem;
    }
}

</style>
